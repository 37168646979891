import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "../../lib/utils";

const buttonVariants = cva(
  "chatbot-inline-flex chatbot-items-center chatbot-justify-center chatbot-whitespace-nowrap chatbot-rounded-md chatbot-text-sm chatbot-font-medium chatbot-transition-colors focus-visible:chatbot-outline-none focus-visible:chatbot-ring-1 focus-visible:chatbot-ring-ring disabled:chatbot-pointer-events-none disabled:chatbot-opacity-50",
  {
    variants: {
      variant: {
        default:
          "chatbot-bg-primary chatbot-text-primary-foreground chatbot-shadow hover:chatbot-bg-primary/90",
        destructive:
          "chatbot-bg-destructive chatbot-text-destructive-foreground chatbot-shadow-sm hover:chatbot-bg-destructive/90",
        outline:
          "chatbot-border chatbot-border-input chatbot-bg-background chatbot-shadow-sm hover:chatbot-bg-accent hover:chatbot-text-accent-foreground",
        secondary:
          "chatbot-bg-secondary chatbot-text-secondary-foreground chatbot-shadow-sm hover:chatbot-bg-secondary/80",
        ghost: "hover:chatbot-bg-accent hover:chatbot-text-accent-foreground",
        link: "chatbot-text-primary chatbot-underline-offset-4 hover:chatbot-underline",
        rounded: "chatbot-bg-[rgba(255,255,255,0.75)] hover:chatbot-bg-white"
      },
      size: {
        default: "chatbot-h-9 chatbot-px-4 chatbot-py-2",
        sm: "chatbot-h-8 chatbot-rounded-md chatbot-px-3 chatbot-text-xs",
        lg: "chatbot-h-10 chatbot-rounded-md chatbot-px-8",
        icon: "!chatbot-rounded-full chatbot-h-14 chatbot-w-14",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
