import { Button } from "./components/ui/button";
import ReactMarkdown from "react-markdown";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from "./components/ui/chatDialog";
import { cn } from "./lib/utils";
import { useChat } from "ai/react";
import { useEffect, useRef } from "react";
import { ImageComp } from "./components/ImageComp";
import { ExportComp } from "./components/ExportComp";
import { content, exampleMessages } from "./content";

const getLanguage = (): "en" | "ar" => {
  const language = /\?lang=(..)/.exec(window.location.href)?.[1] ?? "en";
  return language === "ar" ? "ar" : "en";
};
export default function Chat() {
  const language = getLanguage();
  const isRtl = language === "ar";
  const text = content[language];

  const ref = useRef<HTMLDivElement>(null);
  const { messages, input, handleInputChange, handleSubmit, append } = useChat({
    onError: (e) => console.error(e),
    streamMode: "text",
    api:
      process.env.NODE_ENV === "development"
        ? undefined
        : "https://daleel-chatbot.vercel.app/api/chat",
  });
  const lastMessage = messages[messages.length - 1];
  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  }, [lastMessage]);
  return (
    <>
      <Dialog>
        <DialogTrigger asChild>
          <Button
            variant="rounded"
            size="icon"
            className={cn(
              "chatbot-fixed chatbot-bottom-8",
              isRtl ? "chatbot-left-8" : "chatbot-right-8"
            )}
          >
            <img
              src={"https://daleel-chatbot.vercel.app/chat.png"}
              width={32}
              height={32}
              alt="Chat"
            />
          </Button>
        </DialogTrigger>
        <DialogContent
          className="chatbot-flex chatbot-flex-col"
          fullHeight={messages.length > 0}
          isRtl={isRtl}
        >
          <DialogTitle className="chatbot-text-lg chatbot-text-start">
            {text.title}
          </DialogTitle>
          <div
            ref={ref}
            className="chatbot-flex-1 chatbot-flex chatbot-flex-col chatbot-w-full chatbot-py-4 chatbot-mx-auto chatbot-stretch chatbot-relative chatbot-overflow-auto  chatbot-space-y-4"
          >
            {messages.length ? (
              messages.map((m) => (
                <div
                  className="chatbot-elative chatbot-flex chatbot-items-start"
                  key={m.id}
                >
                  <div className="chatbot-bg-background chatbot-flex chatbot-size-[25px] chatbot-shrink-0 chatbot-select-none chatbot-items-center chatbot-justify-center chatbot-text-violet-700">
                    {m.role === "user" ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 256 256"
                        fill="currentColor"
                      >
                        <path d="M230.92 212c-15.23-26.33-38.7-45.21-66.09-54.16a72 72 0 1 0-73.66 0c-27.39 8.94-50.86 27.82-66.09 54.16a8 8 0 1 0 13.85 8c18.84-32.56 52.14-52 89.07-52s70.23 19.44 89.07 52a8 8 0 1 0 13.85-8ZM72 96a56 56 0 1 1 56 56 56.06 56.06 0 0 1-56-56Z"></path>
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="-5 -5 30 30"
                        width={25}
                        height={25}
                        fill="currentColor"
                      >
                        <path
                          d="M4.30737 14.9614L7.69198 11.2053L1.47473 10.0507L0.210938 16.9199L7.04801 18.1914L7.90025 13.5535L4.30737 14.9614Z"
                          fill="currentColor"
                        />
                        <path
                          d="M4.43652 1.74896L10.5666 8.3161L11.8062 1.57844L3.31799 0L1.74915 8.53531L8.30537 9.7581L4.43652 1.74896Z"
                          fill="currentColor"
                        />
                        <path
                          d="M13.1948 1.83258L12.023 8.19022L16.7586 6.33406L14.231 9.14019L18.7536 9.98302L20.0222 3.1041L13.1948 1.83258Z"
                          fill="currentColor"
                        />
                      </svg>
                    )}
                  </div>
                  <div className="chatbot-ml-4 chatbot-flex-1 chatbot-space-y-2 chatbot-overflow-hidden chatbot-px-1">
                    <ReactMarkdown
                      components={{
                        p({ children }: { children?: React.ReactNode }) {
                          return (
                            <p className="chatbot-text-sm chatbot-mb-2 last:chatbot-mb-0">
                              {children}
                            </p>
                          );
                        },
                        h1({ children }: { children?: React.ReactNode }) {
                          return (
                            <h1 className="chatbot-text-lg chatbot-font-bold">
                              {children}
                            </h1>
                          );
                        },
                        h2({ children }: { children?: React.ReactNode }) {
                          return (
                            <h2 className="chatbot-text-[16px] chatbot-font-bold chatbot-mt-1">
                              {children}
                            </h2>
                          );
                        },
                        h3({ children }: { children?: React.ReactNode }) {
                          return (
                            <h3 className="chatbot-text-sm chatbot-font-bold chatbot-mt-1">
                              {children}
                            </h3>
                          );
                        },
                        h4({ children }: { children?: React.ReactNode }) {
                          return (
                            <h4 className="chatbot-text-sm chatbot-font-bold chatbot-mt-1">
                              {children}
                            </h4>
                          );
                        },
                        h5({ children }: { children?: React.ReactNode }) {
                          return (
                            <h5 className="chatbot-text-sm chatbot-font-bold chatbot-mt-1">
                              {children}
                            </h5>
                          );
                        },
                        h6({ children }: { children?: React.ReactNode }) {
                          return (
                            <h6 className="chatbot-text-sm chatbot-font-bold chatbot-mt-1">
                              {children}
                            </h6>
                          );
                        },
                        img: ImageComp,
                        ol({ children }: { children?: React.ReactNode }) {
                          return (
                            <ol
                              className="chatbot-list-decimal chatbot-flex chatbot-flex-col chatbot-gap-2 chatbot-text-sm"
                              style={{ paddingInlineStart: 20 }}
                            >
                              {children}
                            </ol>
                          );
                        },
                        ul({ children }: { children?: React.ReactNode }) {
                          return (
                            <ul
                              className="chatbot-list-disc chatbot-flex chatbot-flex-col chatbot-gap-2 chatbot-text-sm"
                              style={{ paddingInlineStart: 20 }}
                            >
                              {children}
                            </ul>
                          );
                        },
                        li({ children }: { children?: React.ReactNode }) {
                          return (
                            <li className="chatbot-text-sm">{children}</li>
                          );
                        },
                        a({
                          children,
                          href,
                        }: {
                          children?: React.ReactNode;
                          href?: string;
                        }) {
                          return (
                            href &&
                            (href.endsWith(".svg") ? (
                              <ImageComp src={href} alt={children as string} />
                            ) : (
                              <a
                                href={href}
                                className="chatbot-text-violet-900 chatbot-text-sm"
                              >
                                {children}
                              </a>
                            ))
                          );
                        },
                      }}
                      className={cn(
                        "chatbot-prose chatbot-break-words dark:chatbot-prose-invert prose-p:chatbot-leading-relaxed prose-pre:chatbot-p-0",
                        m.role === "user" && "chatbot-text-violet-900"
                      )}
                    >
                      {m.content}
                    </ReactMarkdown>
                  </div>
                </div>
              ))
            ) : (
              <div className="chatbot-flex chatbot-flex-col chatbot-gap-2 chatbot-rounded-lg chatbot-bg-violet-50 chatbot-p-8">
                <p className="chatbot-leading-normal chatbot-text-muted-foreground chatbot-text-sm">
                  {text.description}
                </p>
              </div>
            )}
          </div>
          {messages.length === 0 && (
            <div className="chatbot-mb-4 chatbot-grid chatbot-grid-cols-1 sm:chatbot-grid-cols-2 chatbot-gap-2 chatbot-px-4 sm:chatbot-px-0">
              {exampleMessages.map((example, index) => (
                <div
                  key={example[language].heading}
                  className={`chatbot-cursor-pointer chatbot-rounded-lg chatbot-border chatbot-bg-violet-50 chatbot-p-4 hover:chatbot-bg-violet-100 ${
                    index > 1 && "chatbot-hidden md:chatbot-block"
                  }`}
                  onClick={() => {
                    append({
                      content: example[language].message,
                      role: "user",
                    });
                  }}
                >
                  <div className="chatbot-text-sm chatbot-font-semibold">
                    {example[language].heading}
                  </div>
                </div>
              ))}
            </div>
          )}
          <ExportComp messages={messages} language={language} isRtl={isRtl} />
          <form onSubmit={handleSubmit} className="chatbot-flex chatbot-gap-1">
            <input
              className="chatbot-bottom-0 chatbot-w-full chatbot-p-2 chatbot-border chatbot-border-gray-300 chatbot-rounded chatbot-shadow-xl chatbot-text-sm chatbot-flex-1"
              value={input}
              placeholder={text.send}
              onChange={handleInputChange}
            />
            <button
              type="submit"
              className="chatbot-rounded-full chatbot-w-10 chatbot-h-10 chatbot-flex chatbot-items-center chatbot-justify-center chatbot-bg-violet-400 chatbot-text-white chatbot-shadow-xl disabled:chatbot-bg-gray-400 hover:chatbot-bg-violet-500"
              disabled={!input}
            >
              <SendIcon />
            </button>
          </form>
          <p className="chatbot-text-xs chatbot-text-center chatbot-text-gray-500">
            {text.disclaimer.split("\n").map((line, index) => (
              <span key={index} className="chatbot-block">
                {line}
              </span>
            ))}
          </p>
        </DialogContent>
      </Dialog>
    </>
  );
}
function SendIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="chatbot-h-5 chatbot-w-5"
    >
      <path d="m22 2-7 20-4-9-9-4Z" />
      <path d="M22 2 11 13" />
    </svg>
  );
}
