export const content = {
  en: {
    title: "REEMAI",
    description:
      "REEMAI can help you by providing information regarding NIDLP sectorial value propositions, enablement packages and investment journey details.",
    disclaimer:
      "Welcome to (REEMAI), the experimental AI chatbot, which strives - as much as possible -\nto provide accurate answers to inquiries related to (Daleel).",
    email: "enter your email",
    export: "export chat",
    send: "Say something...",
    sendError: "error sending email, please try again",
    close: "close",
    sent: "Email sent successfully",
  },
  ar: {
    title: "ريماي",
    description:
      "يمكن لريماي مساعدتك من خلال توفير المعلومات المتعلقة بمقترحات القيمة القطاعية وحزم التمكين وتفاصيل رحلة الاستثمار.",
    disclaimer:
      "مرحبًا بك في (REEMAI)، روبوت الدردشة التجريبي للذكاء الاصطناعي، الذي يسعى –بقدر الإمكان- لتقديم\nإجابات دقيقة للاستفسارات المتعلقة بـ(دليل).",
    email: "أدخل بريدك الالكتروني",
    export: "تصدير الدردشة",
    send: "اكتب سؤالك...",
    sendError: "خطأ في إرسال البريد الإلكتروني، يرجى المحاولة مرة أخرى",
    close: "إغلاق",
    sent: "تم إرسال البريد الإلكتروني بنجاح",
  },
};

export const exampleMessages = [
  {
    en: {
      heading:
        "What are the market insights for Mining downstream Steel industry?",
      message:
        "What are the market insights for Mining downstream Steel industry?",
    },
    ar: {
      heading: "ما هي بيانات سوق صناعة الحديد والصلب؟",
      message: "ما هي بيانات سوق صناعة الحديد والصلب؟",
    },
  },
  {
    en: {
      heading:
        "What are the market insights for intermediate chemicals industry?",
      message:
        "What are the market insights for intermediate chemicals industry?",
    },
    ar: {
      heading: "ما هي بيانات سوق صناعة الكيماويات الوسيطة؟",
      message: "ما هي بيانات سوق صناعة الكيماويات الوسيطة؟",
    },
  },
  {
    en: {
      heading: "What is the investor journey for mining downstream titanium?",
      message: "What is the investor journey for mining downstream titanium?",
    },
    ar: {
      heading: "ما هي رحلة المستثمر لتعدين التيتانيوم؟",
      message: "ما هي رحلة المستثمر لتعدين التيتانيوم؟",
    },
  },
  {
    en: {
      heading: "what is tawteen program?",
      message: "what is tawteen program?",
    },
    ar: {
      heading: "ما هو برنامج توطين؟",
      message: "ما هو برنامج توطين؟",
    },
  },
];
