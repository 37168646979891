import { Dialog, DialogContent, DialogTrigger } from "./ui/dialog";
import { Message } from "ai";
import { ChangeEvent, FormEvent, useState } from "react";
import { content } from "../content";

export const LoadingSpinner = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="chatbot-animate-spin"
  >
    <path d="M21 12a9 9 0 1 1-6.219-8.56" />
  </svg>
);
export const ExportComp = ({
  messages,
  language,
  isRtl,
}: {
  messages: Message[];
  language: "en" | "ar";
  isRtl: boolean;
}) => {
  const text = content[language];
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const [open, setOpen] = useState(false);
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    if (!email) {
      return;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, messages }),
    };
    try {
      await fetch(
        process.env.NODE_ENV === "development"
          ? "http://localhost:3000/api/export"
          : "https://daleel-chatbot.vercel.app/api/export",
        requestOptions
      );
      setSent(true);
    } catch (e) {
      console.error(e);
      setError(text.sendError);
    } finally {
      setEmail("");
      setLoading(false);
    }
  };
  return messages.length ? (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <button className="chatbot-bg-gray-300 hover:chatbot-bg-gray-400 chatbot-text-gray-800 chatbot-font-bold chatbot-py-2 chatbot-px-4 chatbot-rounded chatbot-flex chatbot-items-center chatbot-w-auto chatbot-justify-center chatbot-gap-2 chatbot-self-end chatbot-text-sm">
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 20C7.58172 20 4 16.4183 4 12M20 12C20 14.5264 18.8289 16.7792 17 18.2454"
              stroke="#1C274C"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
            <path
              d="M12 14L12 4M12 4L15 7M12 4L9 7"
              stroke="#1C274C"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span>{text.export}</span>
        </button>
      </DialogTrigger>
      <DialogContent className="chatbot-pt-10 chatbot-w-[350px]" isRtl={isRtl}>
        {sent ? (
          <div className="chatbot-flex chatbot-flex-col chatbot-justify-center">
            <p className="chatbot-text-gray-900 chatbot-text-sm chatbot-text-center chatbot-mb-6">
              {text.sent}
            </p>

            <button
              onClick={() => {
                setOpen(false);
                setSent(false);
              }}
              className="chatbot-bg-green-300  hover:chatbot-bg-green-400 chatbot-text-gray-900 chatbot-font-bold chatbot-py-2 chatbot-px-4 chatbot-rounded chatbot-w-auto chatbot-justify-center chatbot-text-sm"
              type="button"
            >
              {text.close}
            </button>
          </div>
        ) : (
          <form
            onSubmit={handleSubmit}
            className="chatbot-flex chatbot-flex-col chatbot-gap-4 chatbot-p-4"
          >
            <input
              className="chatbot-bottom-0  chatbot-p-2 chatbot-border chatbot-border-gray-300 chatbot-rounded chatbot-text-sm"
              value={email}
              placeholder={text.email}
              onChange={onChange}
            />
            {error && (
              <p className="chatbot-text-red-500 chatbot-text-sm chatbot-text-center">
                {error}
              </p>
            )}
            <button
              disabled={!email}
              className="chatbot-bg-gray-300 disabled:chatbot-bg-gray-300 hover:chatbot-bg-gray-400 chatbot-text-gray-800 chatbot-font-bold chatbot-py-2 chatbot-px-4 chatbot-rounded chatbot-flex chatbot-items-center chatbot-w-auto chatbot-justify-center chatbot-gap-2 chatbot-text-sm"
              type="submit"
            >
              {loading ? (
                <LoadingSpinner />
              ) : (
                <>
                  <svg
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 20C7.58172 20 4 16.4183 4 12M20 12C20 14.5264 18.8289 16.7792 17 18.2454"
                      stroke="#1C274C"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                    />
                    <path
                      d="M12 14L12 4M12 4L15 7M12 4L9 7"
                      stroke="#1C274C"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span>{text.export}</span>
                </>
              )}
            </button>
          </form>
        )}
      </DialogContent>
    </Dialog>
  ) : null;
};
