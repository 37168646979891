import {Dialog, DialogContent, DialogTrigger} from "./ui/dialog";

export const ImageComp = ({src, alt}: { src?: string; alt?: string }) => {
    if (!src || !alt) return null
    return (
        <Dialog>
            <DialogTrigger asChild>
                <img src={src} alt={alt} className="chatbot-cursor-pointer chatbot-pt-4 chatbot-pb-8 chatbot-w-1/3"/>
            </DialogTrigger>
            <DialogContent className="chatbot-pt-10 chatbot-w-[90vw] sm:chatbot-w-2/3 md:chatbot-w-1/2">
                <img src={src} alt={alt} className="chatbot-w-full"/>
            </DialogContent>
        </Dialog>
    );
};
